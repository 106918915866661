import { useCallback, useMemo, useRef, useState } from 'react';

import { uuidv4 } from '../utils/common';
import { useForceUpdate } from './useForceUpdate';
import { useLiveCallback } from './useLiveCallback';

export enum AsyncCallState {
  NotStarted = 'NotStarted',
  Running = 'Running',
  Done = 'Done',
}

export type TransformedAsyncCallState = {
  isStarted: boolean;
  isRunning: boolean;
  isDone: boolean;
};

export type AsyncCall<T extends AsyncFunction> = {
  state: {
    raw: AsyncCallState;
    transformed: TransformedAsyncCallState;
  };
  error: Error | null;
  call: (...arg: Parameters<T>) => Promise<Awaited<ReturnType<T>> | undefined>;
  reset: () => void;
};

export function useAsyncCall<T extends AsyncFunction>(fn: T): AsyncCall<T> {
  const [state, setState] = useState<AsyncCallState>(AsyncCallState.NotStarted);
  const [error, setError] = useState<Error | null>(null);
  const asyncIdRef = useRef<string | null>(null);

  const call = useCallback(
    async (...arg: Parameters<T>) => {
      const id = uuidv4();
      asyncIdRef.current = id;
      setState(AsyncCallState.Running);
      setError(null);
      let error;
      let resp;
      try {
        resp = await fn(...arg);
      } catch (err: UnassertedUnknown) {
        error = err;
      }
      if (id !== asyncIdRef.current) {
        // If the id check failed, it means the current async call is outdated,
        // the caller calls this function again without await, skip updating the local state in this case.
        return resp;
      }
      if (error) setError(error);
      setState(AsyncCallState.Done);
      return resp;
    },
    [fn]
  );

  const reset = useCallback(() => {
    setState(AsyncCallState.NotStarted);
    setError(null);
  }, []);

  const transformed = useTransformedAsyncCallState(state);

  return {
    state: {
      raw: state,
      transformed: transformed,
    },
    error,
    call,
    reset,
  };
}

// export function useLiveAsyncCall<T extends AsyncFunction>(fn: T): AsyncCall<T> {
//   return useAsyncCall(useLiveCallback(fn));
// }

export type LiveAsyncCall<T extends AsyncFunction> = {
  state: {
    state: TransformedAsyncCallState;
    error: Error | null;
  };
  call: (...arg: Parameters<T>) => ReturnType<T>;
  reset: () => void;
};

export function useLiveAsyncCall<T extends AsyncFunction>(
  fn: T
): LiveAsyncCall<T> {
  const ref = useRef<{ state: TransformedAsyncCallState; error: Error | null }>(
    {
      state: getTransformedState(AsyncCallState.NotStarted),
      error: null,
    }
  );
  const asyncIdRef = useRef<string | null>(null);
  const forceUpdate = useForceUpdate();
  const liveFn = useLiveCallback(fn);

  const call = useCallback(
    async (...arg: Parameters<T>) => {
      const id = uuidv4();
      asyncIdRef.current = id;
      ref.current.state = getTransformedState(AsyncCallState.Running);
      ref.current.error = null;
      forceUpdate();
      let error;
      let resp;
      try {
        resp = await liveFn(...arg);
      } catch (err: UnassertedUnknown) {
        error = err;
      }
      if (id !== asyncIdRef.current) {
        // If the id check failed, it means the current async call is outdated,
        // the caller calls this function again without await, skip updating the local state in this case.
        return resp;
      }
      if (error) ref.current.error = error;
      ref.current.state = getTransformedState(AsyncCallState.Done);
      forceUpdate();
      return resp;
    },
    [forceUpdate, liveFn]
  );

  const reset = useCallback(() => {
    ref.current.state = getTransformedState(AsyncCallState.NotStarted);
    ref.current.error = null;
    forceUpdate();
  }, [forceUpdate]);

  return {
    state: ref.current,
    call: call as LiveAsyncCall<T>['call'],
    reset,
  };
}

function getTransformedState(state: AsyncCallState): TransformedAsyncCallState {
  return {
    isStarted: state !== AsyncCallState.NotStarted,
    isRunning: state === AsyncCallState.Running,
    isDone: state === AsyncCallState.Done,
  };
}

function useTransformedAsyncCallState(
  state: AsyncCallState
): TransformedAsyncCallState {
  return useMemo(() => getTransformedState(state), [state]);
}
