import { useCallback, useRef } from 'react';

import { useSSRLayoutEffect } from '../../app/components/hooks/useSSRLayoutEffect';

// https://gist.github.com/developit/6c1c3df427e5f3dda80d0e32a5809d72

/**
 * Return a stable reference to a callback that always has the values from the
 * most recent render. Useful for event handler callbacks and APIs.
 */
export const useLiveCallback = <A extends unknown[], R>(
  fn: (...args: A) => R
): ((...args: A) => R) => {
  const callback = useRef(fn);
  useSSRLayoutEffect(
    function () {
      callback.current = fn;
    },
    [fn]
  );
  return useCallback((...args: A) => callback.current(...args), [callback]);
};
