import { useCallback, useState } from 'react';

// code borrowed from https://github.com/CharlesStover/use-force-update
export function useForceUpdate(): () => void {
  const [, force] = useState<unknown>(Object.create(null));

  const memoizedDispatch = useCallback((): void => {
    force(Object.create(null));
  }, [force]);
  return memoizedDispatch;
}
